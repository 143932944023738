// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Container from "../../../../styleguide/components/Container/Container.res.js";
import * as DashboardCss from "../../../../styleguide/dashboard/components/views/common/DashboardCss.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardAgencyNavbar from "../common/navbar/DashboardAgencyNavbar.res.js";
import * as DashboardAgencyNewForm from "./components/DashboardAgencyNewForm.res.js";

function DashboardAgencyNew$default(props) {
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(DashboardAgencyNavbar.make, {
                      currentLink: "New"
                    }),
                JsxRuntime.jsx(Container.make, {
                      className: DashboardCss.container,
                      children: JsxRuntime.jsx(DashboardAgencyNewForm.make, {
                            tsds: props.tsdSelectIndex.tsds
                          })
                    })
              ],
              className: DashboardCss.pageContainer
            });
}

var Css;

var $$default = DashboardAgencyNew$default;

export {
  Css ,
  $$default as default,
}
/* Container Not a pure module */
